<template>
  <dialog-outline :scrollable="true" v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <!--      <record-banner :record="record">{{ bannerTitle }}</record-banner>-->

      <v-container fluid>
        <!-- List Agenda Templates -->
        <v-row dense>
          <v-col>
            <v-select
              item-text="name"
              item-value="id"
              v-model="selectedTemplate"
              :loading="isLoadingAgendaTemplates"
              :label="labelAgendaTemplate"
              :prepend-icon="dialogIcon"
              :items="templates"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>

        <!-- Selected Agenda Template details -->
        <v-row dense>
          <v-col>
            <agenda-template-tree-view
              :agenda-template="agendaTemplate"
              :open="visible"
            ></agenda-template-tree-view>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!--  Dialog Actions -->
    <template v-slot:actions>
      <base-button :disabled="!enableOkCommand" @click="onOk">{{
        dialogOkCommandLabel
      }}</base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("agenda");

// model
import {
  agendaTemplateOperation,
  documentAssemblyEntityName,
  entityName,
  findAgendaTemplateOperation,
  formatAgendaTemplateActionName,
  formatDocumentTemplateActionName
} from "@/model/agenda/agendaTempleteModel";
import { actionResultType } from "@/model/action/actionModel";

// utils
import { isEmpty } from "@/utils";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";

export default {
  name: "MeetingTemplateBrowser",
  mixins: [dialogOutlineMixin],
  components: {
    AgendaTemplateTreeView: () =>
      import("@/components/agenda/templates/AgendaTemplateTreeView")
  },
  props: {
    selectAgendaTemplateId: {
      type: Number,
      default: -1
    },

    /**
     * Function to perform
     */
    performAction: {
      type: Function,
      required: true
    },
    isDocumentAssembly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templates: undefined,
      selectedTemplate: undefined,
      agendaTemplate: undefined,
      isLoadingAgendaTemplates: false
    };
  },
  computed: {
    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findAgendaTemplateOperation(agendaTemplateOperation.select);
    },

    /**
     * operation Label
     * @return {string}
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Download Dialog Title
     * @return {string} Formatted download Dialog Title
     */
    dialogTitle() {
      return this.isDocumentAssembly
        ? formatDocumentTemplateActionName(agendaTemplateOperation.select)
        : formatAgendaTemplateActionName(agendaTemplateOperation.select);
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return this.dialogTitle;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * get label Agenda Template
     * @return {string}  Agenda Template label
     */
    labelAgendaTemplate() {
      return this.isDocumentAssembly ? documentAssemblyEntityName : entityName;
    },

    /**
     * computed whether to enable Ok Command
     * @return {boolean}
     */
    enableOkCommand() {
      return !!this.agendaTemplate;
    }
  },
  methods: {
    ...mapActions({
      getMeetingAgendaTemplates: "getMeetingAgendaTemplates",
      getMeetingAgendaTemplate: "getMeetingAgendaTemplate",
      getDocumentAssemblyTemplates: "getDocumentAssemblyTemplates",
      getDocumentAssemblyTemplate: "getDocumentAssemblyTemplate"
    }),

    /**
     * load Agenda Templates
     * @return {Promise<void>}
     */
    async loadTemplates() {
      this.clearAlert();

      this.selectedTemplate = undefined;
      this.isLoadingAgendaTemplates = true;

      try {
        let response;
        if (this.isDocumentAssembly) {
          response = await this.getDocumentAssemblyTemplates();
        } else {
          response = await this.getMeetingAgendaTemplates();
        }

        this.templates = response?.data ?? [];

        if (this.templates.length > 0) {
          if (this.selectAgendaTemplateId > 0) {
            const template = this.templates.find(
              el => el.id === this.selectAgendaTemplateId
            );
            if (template) {
              this.selectedTemplate = template;
            }
          }
          if (!this.selectedTemplate) {
            this.selectedTemplate = this.templates[0];
          }
        }
      } catch (e) {
        console.error(e);
        this.internalAlert = this.createAlertError(
          this.formatAlertError(`Load Agenda Templates`, e)
        );
      } finally {
        this.isLoadingAgendaTemplates = false;
      }
    },

    async loadTemplate(id) {
      this.clearAlert();
      try {
        const response = await (this.isDocumentAssembly
          ? this.getDocumentAssemblyTemplate(id)
          : this.getMeetingAgendaTemplate(id));

        this.agendaTemplate = response?.data;
        console.log(`this.agendaTemplate:`, this.agendaTemplate);
      } catch (e) {
        console.error(e);
        this.internalAlert = this.createAlertError(
          this.formatAlertError(`Get Meeting Agenda Template`, e)
        );
      }
    },

    async onOk() {
      try {
        this.clearAlert();

        const result = await this.performAction(this.agendaTemplate);

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          if (!isEmpty(result.message)) {
            this.internalAlert = this.createAlertWarning(result.message);
          }
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e)
        );
      }
    }
  },
  watch: {
    /**
     * watch visible
     * @param {boolean} newValue
     * @return {Promise<void>}
     */
    async visible(newValue) {
      if (newValue) {
        await this.loadTemplates();
      }
    },
    /**
     * watch selectedTemplate
     * @param {{id: number, name: string}} newValue
     * @return {Promise<void>}
     */
    async selectedTemplate(newValue) {
      if (this.visible) {
        if (newValue) {
          await this.loadTemplate(newValue.id);
        }
      }
    }
  }
};
</script>
